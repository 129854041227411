import React from "react";
import "./Solution.css";
import quotesIcon from "../../assets/SolutionSection/quotes.svg";
import ellipse from "../../assets/SolutionSection/ellipse.png";
import solutionCard2 from "../../assets/SolutionSection/solution-card-2.svg";
import linesIcon from "../../assets/SolutionSection/lines.svg";
import chartIcon from "../../assets/SolutionSection/chart.svg";
import solutionCard5 from "../../assets/SolutionSection/solution-fifth-card.svg";
import useIntersectionObserver from "../customHooks/useIntersectionObserver";
import ContextImage from "../../assets/illustration & Icons/Solution/Contextual Relevancy@2x.png"
import GaurdImage from "../../assets/illustration & Icons/Solution/Guardrails for Security@2x.png"
export default function SolutionSection() {
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });
  return (
    <section
      className={`solution-section ${isVisible ? "section-animation" : ""}`}
      ref={ref}
      id="one"
    >
      <div className="container">
        <div className="section-wrapper row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 column-1">
            <div className="section-heading">
              <div className="heading-tag">
                <span>SOLUTIONS</span>
              </div>
              <div className="heading-description">
                <h2>Agentic AI-Powered Knowledge Base Search </h2>
                <p>
                  Enhance enterprise search capabilities with AI agents for
                  accurate, relevant results across unstructured data sources.
                </p>
              </div>
            </div>
            <div className="solutions-card solutions-third-card">
              <img src={quotesIcon} alt="double-quotes" />
              <h3>Unstructured Data Management</h3>
              <p>
                AgentSearch leverages a powerful vector database to efficiently
                index, manage, and search through vast amounts of unstructured
                data, enabling rapid access to valuable enterprise insights.
              </p>
              <img src={ellipse} alt="ellipse" />
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 column-2">
            <div className="solutions-card solutions-first-card">
              <img src={ContextImage} alt="solution-card-image" />
              <h3>Contextual Relevancy</h3>
              <p>
                AI-driven contextual understanding ensures search results are
                relevant, minimizing irrelevant outputs and saving time.
              </p>
            </div>
            <div className="solutions-card solutions-fourth-card">
              <img src={chartIcon} alt="chart-icon" />
              <h3>Seamless Integrations</h3>
              <p>Integrates effortlessly with existing enterprise systems.</p>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 column-3">
            <div className="solutions-card solutions-second-card">
              <img src={linesIcon} alt="lines-icon" />
              <h3>Compliance Adherence</h3>
              <p>Ensures adherence to data privacy and regulations.</p>
            </div>
            <div className="solutions-card solutions-fifth-card">
              <img src={GaurdImage} alt="solution-card-image" />
              <h3>Guardrails for Security</h3>
              <p>
                Ensures data privacy and compliance through secure, regulated
                search protocols.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
