import React from "react";
import "./Features.css";
import featureCard1 from "../../assets/FeatureSection/features-card-1.svg";
import featureCard2 from "../../assets/FeatureSection/features-card-2.png";
import featureCard3 from "../../assets/FeatureSection/features-card-3.svg";
import featureCard4 from "../../assets/FeatureSection/features-card-4.png";
import useIntersectionObserver from "../customHooks/useIntersectionObserver";
import ContextImage from "../../assets/illustration & Icons/Features/Contextualized Search Results@2x-100.jpg";
import ScalabilityImage from "../../assets/illustration & Icons/Features/Data Scalability@2x-100.jpg";

export default function Features() {
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });

  return (
    <section
      className={`features-section ${isVisible ? "section-animation" : ""}`}
      ref={ref}
      id="three"
    >
      <div className="container">
        <div className="section-wrapper">
          <div className="section-heading">
            <div className="heading-tag">
              <span>FEATURES</span>
            </div>
            <div className="heading-description">
              <h2>Unlock Features of AgentSearch</h2>
              <p>
                Explore how AgentSearch optimizes enterprise data search with
                AI, improving relevancy, scalability, real-time accuracy, and
                secure information retrieval.
              </p>
            </div>
          </div>
        </div>
        <div className="features-cards-wrapper row">
          <div className="col-12 col-sm-4 col-md-5 col-lg-5 col-xl-5 column-1">
            <div className="features-card features-first-card">
              <img src={ContextImage} alt="features-card-image" />
              <h3>Contextualized Search Results</h3>
              <p>
              Enhance search relevance by providing contextually accurate results tailored to user intent and query meaning
              </p>
            </div>
          </div>
          <div className="col-12 col-sm-4 col-md-3 col-lg-3 col-xl-3 column-2">
            <div className="features-card features-second-card">
              <img src={featureCard2} alt="features-card-image" />
              <h3>Real-Time Retrieval</h3>
              <p>
              Access up-to-date information without retraining models, ensuring real-time accuracy in dynamic fields
              </p>
            </div>
            <div className="features-card features-fourth-card">
              <h3>Reduced hallucinations</h3>
              {/* <p>
              With Agentic workflow maintains the highest level of relevancy
              </p> */}
              <img className='round-orange' src={featureCard4} alt="features-card-image" />
            </div>
          </div>
          <div className="col-12 col-sm-4 col-md-3 col-lg-3 col-xl-3 column-3">
            <div className="features-card features-third-card">
              <img src={ScalabilityImage} alt="features-card-image" />
            </div>
            <div className="features-card features-fifth-card">
              <h3>Data Scalability</h3>
              <p>
              Efficiently scale search capabilities across structured and unstructured data for enterprise-wide information management
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
