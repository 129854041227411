import React, { useState } from "react";
import "./UseCases.css";
import tabSwitchCardImg1 from "../../assets/UseCasesSection/tab-switch-card-1.svg";
import tabSwitchCardImg2 from "../../assets/UseCasesSection/tab-switch-card-2.svg";
import tabSwitchCardImg3 from "../../assets/UseCasesSection/tab-switch-card-3.svg";
import useIntersectionObserver from "../customHooks/useIntersectionObserver";

import auditImage from "../../assets/illustration & Icons/Use case/Compliance Access/Audit Trails and Monitoring@2x-100.jpg"
import regulatoryImage from "../../assets/illustration & Icons/Use case/Compliance Access/Regulatory Compliance Updates@2x-100.jpg"
import secureImage from "../../assets/illustration & Icons/Use case/Compliance Access/Secure document retrieval@2x-100.jpg"

import twentyFourImage from "../../assets/illustration & Icons/Use case/Customer query resolution/24-7 Availability@2x-100.jpg"
import fastAndAccurate from "../../assets/illustration & Icons/Use case/Customer query resolution/Fast and accurate@2x-100.jpg"
import multiLingual from "../../assets/illustration & Icons/Use case/Customer query resolution/Multilingual Support@2x-100.jpg"

import enterpriseSearch from "../../assets/illustration & Icons/Use case/Enterprise Search Simplification/Advanced Filtering@2x-100.jpg"
import CrossPlatform from "../../assets/illustration & Icons/Use case/Enterprise Search Simplification/Cross-Platform Search@2x-100.jpg"
import easyDocument from "../../assets/illustration & Icons/Use case/Enterprise Search Simplification/Easy document access@2x-100.jpg"

import empower from "../../assets/illustration & Icons/Use case/Knowledge Integration/Empower AI avatars@2x-100.jpg"
import personalizedKnowledge from "../../assets/illustration & Icons/Use case/Knowledge Integration/Personalized Knowledge Delivery@2x-100.jpg"
import realTime from "../../assets/illustration & Icons/Use case/Knowledge Integration/Real-Time Knowledge Updates@2x-100.jpg"



const UseCases = () => {
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });
  const [activeTab, setActiveTab] = useState(1);
  const handleTabSwitch = (index) => {
    setActiveTab(index);
  };
  return (
    <section
      className={`use-cases-section ${isVisible ? "section-animation" : ""}`}
      ref={ref}
      id="two"
    >
      <div className="container">
        <div className="section-wrapper">
          <div className="section-heading">
            <div className="heading-tag">
              <span>USE CASES</span>
            </div>
            <div className="heading-description">
              <h2>Real World Use Cases of AgentSearch</h2>
              <p>
                Discover how AgentSearch transforms enterprise data management
                with intelligent, context-aware, and efficient solutions.
              </p>
            </div>
          </div>
          <div className="second-tab-switch-wrapper">
            <div className="tab-links-wrapper">
              <div
                className={`${activeTab === 1 ? "active" : ""}`}
                onClick={() => handleTabSwitch(1)}
              >
                <p>Customer Query Resolution</p>
              </div>
              <div
                className={`${activeTab === 2 ? "active" : ""}`}
                onClick={() => handleTabSwitch(2)}
              >
                <p>Compliance Access</p>
              </div>
              <div
                className={`${activeTab === 3 ? "active" : ""}`}
                onClick={() => handleTabSwitch(3)}
              >
                <p>Enterprise Search Simplification</p>
              </div>
              <div
                className={`${activeTab === 4 ? "active" : ""}`}
                onClick={() => handleTabSwitch(4)}
              >
                <p>Knowledge Integration</p>
              </div>
            </div>
            <div className="second-tab-switch-body">
              <div
                className={`second-tab-switch-content ${activeTab === 1 ? "active" : ""
                  }`}
              >
                <div className="second-tab-switch-cards">
                  <div className="tab-switch-card first-card">
                    <div className="card-text">
                      <h3>Fast and accurate</h3>
                      <p>
                        Resolve customer queries swiftly by retrieving precise knowledge base information efficiently
                      </p>
                    </div>
                    <div className="card-image">
                      <img
                        src={fastAndAccurate}
                        alt="tab-switch-card-image"
                      />
                    </div>
                  </div>
                  <div className="tab-switch-card second-card">
                    <div className="card-text">
                      <h3>24/7 Availability</h3>
                      <p>
                      Empower customer support with AI agents for instant access to knowledge bases, ensuring query resolutions
                      </p>
                    </div>
                    <div className="card-image">
                      <img
                        src={twentyFourImage}
                        alt="tab-switch-card-image"
                      />
                    </div>
                  </div>
                  <div className="tab-switch-card third-card">
                    <div className="card-text">
                      <h3>Multilingual Support</h3>
                      <p>
                        Handle customer queries in various languages, to ensure consistent support for a global audience
                      </p>
                    </div>
                    <div className="card-image">
                      <img
                        src={multiLingual}
                        alt="tab-switch-card-image"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`second-tab-switch-content ${activeTab === 2 ? "active" : ""
                  }`}
              >
                <div className="second-tab-switch-cards">
                  <div className="tab-switch-card first-card">
                    <div className="card-text">
                      <h3>Secure document retrieval</h3>
                      <p>
                        Access compliance-related documents securely, ensuring all regulatory requirements are met promptly
                      </p>
                    </div>
                    <div className="card-image">
                      <img
                        src={secureImage}
                        alt="tab-switch-card-image"
                      />
                    </div>
                  </div>
                  <div className="tab-switch-card second-card">
                    <div className="card-text">
                      <h3>Regulatory Compliance</h3>
                      <p>
                        Automatically stay updated with the latest regulatory changes and reflect them in document access protocols
                      </p>
                    </div>
                    <div className="card-image">
                      <img
                        src={regulatoryImage}
                        alt="tab-switch-card-image"
                      />
                    </div>
                  </div>
                  <div className="tab-switch-card third-card">
                    <div className="card-text">
                      <h3>Audit Trails and Monitoring</h3>
                      <p>
                        Automatically log access and retrieval actions for compliance documents, creating a transparent audit trail
                      </p>
                    </div>
                    <div className="card-image">
                      <img
                        src={auditImage}
                        alt="tab-switch-card-image"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`second-tab-switch-content ${activeTab === 3 ? "active" : ""
                  }`}
              >
                <div className="second-tab-switch-cards">
                  <div className="tab-switch-card first-card">
                    <div className="card-text">
                      <h3>Easy document access</h3>
                      <p>
                        Quickly locate enterprise-wide documents with AI-driven, accurate and context-aware searches
                      </p>
                    </div>
                    <div className="card-image">
                      <img
                        src={easyDocument}
                        alt="tab-switch-card-image"
                      />
                    </div>
                  </div>
                  <div className="tab-switch-card second-card">
                    <div className="card-text">
                      <h3>Advanced Filtering</h3>
                      <p>
                        Apply intelligent filters within AI-driven search systems to narrow down results for specific enterprise documents
                      </p>
                    </div>
                    <div className="card-image">
                      <img
                        src={enterpriseSearch}
                        alt="tab-switch-card-image"
                      />
                    </div>
                  </div>
                  <div className="tab-switch-card third-card">
                    <div className="card-text">
                      <h3>Cross-Platform Search</h3>
                      <p>
                        Integrate various data sources to enable seamless document retrieval across all enterprise platforms and knowledge bases
                      </p>
                    </div>
                    <div className="card-image">
                      <img
                        src={CrossPlatform}
                        alt="tab-switch-card-image"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`second-tab-switch-content ${activeTab === 4 ? "active" : ""
                  }`}
              >
                <div className="second-tab-switch-cards">
                  <div className="tab-switch-card first-card">
                    <div className="card-text">
                      <h3>Empower AI avatars</h3>
                      <p>
                        Enhance AI avatars with real-time knowledge, making interactions more relevant and informative
                      </p>
                    </div>
                    <div className="card-image">
                      <img
                        src={empower}
                        alt="tab-switch-card-image"
                      />
                    </div>
                  </div>
                  <div className="tab-switch-card second-card">
                    <div className="card-text">
                      <h3>Knowledge Delivery</h3>
                      <p>
                        Tailor AI avatar responses based on user profiles and preferences, making every interaction more personalized and impactful
                      </p>
                    </div>
                    <div className="card-image">
                      <img
                        src={personalizedKnowledge}
                        alt="tab-switch-card-image"
                      />
                    </div>
                  </div>
                  <div className="tab-switch-card third-card">
                    <div className="card-text">
                      <h3>Real-Time Knowledge Bases</h3>
                      <p>
                        Automatically update AI systems with the latest enterprise data, ensuring real-time access to relevant knowledge
                      </p>
                    </div>
                    <div className="card-image">
                      <img
                        src={realTime}
                        alt="tab-switch-card-image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UseCases;
